@import '~blip-ds/dist/collection/styles/_colors.scss';

.templates-table {
  background-color: $color-surface-0;
  overflow-y: auto;
  margin: 0.5rem 0;

  .templates-table-header {
    border-bottom-color: $color-surface-2;
  }
}
