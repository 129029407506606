.messages-container {
  overflow-y: auto;
  padding: 10px;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  margin: 5px 0;

  &.sent {
    align-items: flex-end;
  }

  &.received {
    align-items: flex-start;
  }

  .timestamp {
    font-size: 0.75rem;
    color: #888;
    margin-top: 0.3125rem;
  }
}

.date-separator {
  text-align: center;
  margin: 0.625rem 0;
  font-size: 0.875rem;
  color: #888;
}

.date-label {
  font-size: 0.875rem;
  color: #888;
  background: #f9f9f9;
  display: inline-block;
  padding: 0 10px;
}

.contact-details p {
  font-size: 0.875rem;
}
