@import '~blip-ds/dist/collection/styles/_colors.scss';

.chat-list {
  display: flex;
  flex-direction: column;
}

.contact-name {
  font-weight: bold;
  width: 40%;
}

.contact-last-message-time {
  color: #888;
}

.contact-card {
  max-width: 18.1875rem;
  width: 100%;
  height: 3.5rem;
  box-shadow: none !important;
}

.contact-card.selected {
  background-color: #cfcfcf !important;
}

.disabled {
  background-color: #cfcfcf !important;
  min-width: 18.1875rem;
}

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #e0e0e0;
  position: relative;
}

.overlay-icon {
  position: absolute;
  bottom: 0;
  left: 1.625rem;
  background-color: #ffffff;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
}
