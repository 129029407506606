@import '~blip-ds/dist/collection/styles/_colors.scss';

.active-messages-pro-view-send-confirmation {
  background-color: $color-surface-0 !important;
  overflow-y: auto;

  .edit-box {
    background-color: $color-surface-1;
    border-radius: 0.75rem;
  }

  .cancel-button-box {
    width: 100%;
  }

  .horizontal-line {
    border-top: 1px solid $color-surface-2;
  }

  .define-send-name {
    margin: 1rem 0;
  }

  .identifier-name {
    max-width: 27.5rem;
  }

  .send-message-button {
    width: 12.375rem;
  }
}
