.container-contacts {
  background-color: #e0e0e0;
  border-radius: 1rem;
  margin: 1.25rem;
  height: 80vh;
  display: flex;
  flex-direction: row;
}

.chat-list-container {
  height: 100%;
  overflow-y: auto;
}
