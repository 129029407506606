@import '~blip-ds/dist/collection/styles/_colors.scss';

.conainter {
  background: white;
  height: 100vh;
}

.container-body {
  width: 49.5rem;
  background-color: #ffffff;
  border-radius: 0.75rem;
  border: 0.0625rem solid $color-border-2;
  gap: 1.5rem;
  padding: 2.5rem 2rem;
  text-align: center;
}
