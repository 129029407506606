.gpt-chat-bubble {
  padding: 0.5rem 1rem;
  display: table;
  clear: both;
  margin-bottom: 0.5rem;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  max-width: 60%;

  &.assistant {
    background-color: #454545;
    border-radius: 1rem 1rem 0 1rem;
    margin-left: auto;
    align-self: flex-end;
    font-palette: white;
  }

  &.user {
    border-radius: 1rem 1rem 1rem 0;
    margin-right: auto;
  }

  &.default {
    background-color: #b2dffd;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    max-width: 21rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    text-align: center;

    strong {
      font-weight: 700;
    }
  }

  @media (min-width: 768px) {
    &.user {
      background-color: #f6f6f6;
    }
  }

  img {
    max-width: 100%;
    max-height: 7.5rem;
    object-fit: cover;
    border-radius: 0.25rem;
  }
}
