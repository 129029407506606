@import '~blip-ds/dist/collection/styles/_colors.scss';

.active-messages-pro-view-message-preview {
  background-color: $color-surface-0 !important;
  overflow-y: auto;

  .preview-box {
    border-bottom: 1px solid $color-surface-1;
  }

  .preview-template {
    max-width: 25rem;
    background-color: $color-surface-1;
    border-radius: 0.75rem;
  }

  .template-buttons {
    border-top: 1px solid $color-surface-2;
  }

  .example-table-disclaimer {
    margin-top: 1rem;
  }

  .example-table {
    border: 1px solid $color-surface-2;
    border-radius: 0.75rem;
  }

  .audience-title {
    margin-bottom: 0.25rem;
  }

  .audience-description {
    margin-bottom: 1.75rem;
  }

  .audience-file-upload {
    max-width: 37.5rem;
  }

  .audience-file-upload-error {
    margin-top: 1rem;
    max-width: 37.5rem;
  }

  .audience-file-upload-model-box {
    cursor: pointer;
  }

  .audience-file-upload-model-title {
    color: $color-surface-primary;
    text-decoration: underline;
  }

  .message-button-separator {
    padding: 0.25rem;
  }

  .message-button {
    border-radius: 0.75rem;
    padding: 0.25rem 0.5rem;
    background-color: $color-surface-0;
    min-width: 10.625rem;
    text-align: center;
  }

  .message-preview-table-example-column {
    border-right: 1px solid $color-surface-2;
    &:last-child {
      border-right: 0;
    }
  }

  .message-preview-table-example-column-header {
    padding: 0.25rem 0.5rem;
    border-bottom: 1px solid $color-surface-2;
  }

  .message-preview-table-example-column-body {
    padding: 0.25rem 0.5rem;
  }

  .variable-separator {
    padding: 0.25rem;
  }

  .variable {
    border: 1px solid $color-surface-2;
    border-radius: 1rem;
    padding: 0.125rem 0.5rem;
    min-width: 4.375rem;
    text-align: center;
  }
}
