@import '~blip-ds/dist/collection/styles/_colors.scss';

.active-messages-pro-view-answers-directing {
  background-color: $color-surface-0 !important;
  overflow-y: auto;

  .description {
    margin-top: 0.25rem;
  }

  .cancel-button-box {
    width: 100%;
  }
}
