@import '~blip-ds/dist/collection/styles/_colors.scss';

.radio-select-answer-directing {
  border: 1px solid $color-surface-2;
  border-radius: 0.75rem;
  cursor: pointer;
  position: relative;

  &.checked {
    border: 2px solid $color-surface-primary;
  }

  .answer-description {
    max-width: 18.75rem;
  }

  .radio-answer {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
