.notification-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b2dffd;
  color: #454545;
  padding: 0.625rem 20px;
  width: 100%;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

.notification-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: calc(100% - 40px);
}

.notification-icon {
  margin-right: 0.625rem;
  font-size: 0.75rem;
}

.notification-message {
  font-size: 1rem;
  margin: 0;
}

.notification-close {
  background: none;
  border: none;
  color: #00796b;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 1.25rem;
}
