@import '~blip-ds/dist/collection/styles/_colors.scss';

.contact-details {
  height: 65%;
  width: 92%;
  padding: 1rem 1.4375rem 1rem 2rem;
  box-shadow: none !important;
}

.contact-details-empty {
  height: 65%;
  width: 92%;
  padding: 1rem 1.4375rem 1rem 2rem;
  box-shadow: none !important;
  background-color: #cfcfcf !important;
}
.details-text {
  font-size: 0.875rem;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.bold {
  font-weight: 700;
  color: $color-content-default;
}
